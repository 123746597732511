<template>
  <div class="h-full">
    
    <div class="bg-white rounded-lg py-1 sm:px-6 flex flex-col h-full">
      <div class="rounded-lg" >
        <div style="mt-2">
        
        <!-- <PersonAddIcon class="w-12 h-12 m-auto text-green-600" :fill="'#000'"/> -->
        <div class="flex justify-center items-center">
          <PersonAddIcon class="h-5 w-5 text-gray-700" aria-hidden="true" :fill="'#fff'"/>
          <h1 class="text-xl font-semibold px-2 text-center text-gray-700">Invite your friends!</h1>
        </div>
        <p class="text-sm px-8 text-center text-gray-500">You'll both get 100 free tickets when they join</p>
         
        <div class="border-gray-200 px-1 pt-1 mb-8  mt-2 rounded-lg">
                  <div class="px-4 pt-2">
                    <input v-model="friendContact" maxlength="50" type="text" name="price" id="price" class="bg-gray-100 rounded-lg py-2 border-b focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300" placeholder="Email or phone number" />
                    <!-- Error Message -->
                    <div class="input-errors" v-for="(error, index) of v$.friendContact.$errors" :key="index">
                      <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
                    </div>
                  </div>
                     <div class="py-2 px-4  flex justify-center">
                      <custom-button :disabled="!v$.friendContact.$invalid" @click="submitForm()">
                        Send invite
                      </custom-button>
                  </div>
        </div>
        </div>
      </div>
    
    <div class="relative mx-8">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
              <span class="px-2 bg-white text-sm text-gray-500">
                Or
              </span>
            </div>
    </div>
         <div class="py-4 px-4 grid grid-cols-2 items-center">
          
          <p class="text-md text-center text-green-700"> Share a direct link:</p>
               <div class="mt-0 flex justify-center">
                    <button type="button" class="inline-flex justify-center w-full m-auto px-1 py-1 border-green-500 shadow-sm text-sm font-medium rounded-lg text-gray-500 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <ShareIcon class="mr-1 h-5 w-5 text-gray-500" aria-hidden="true" :fill="'#fff'"/>
                    <span class="text-sm font-thin">https://besavvy/h54s</span>
                    </button>
                  </div>
             
        </div>
           <div class="flex m-auto justify-center pb-4 mt-4">
                  <FacebookIcon class="mr-2 h-8 w-8 bg-green-600 text-white p-1.5 rounded-full mx-2" aria-hidden="true" :fill="'#fff'"/>
                  <TwitterIcon class="mr-2 h-8 w-8 bg-green-600 text-white p-1.5 rounded-full mx-2" aria-hidden="true" :fill="'#fff'"/>
                  <InstagramIcon class="h-8 w-8 bg-green-600 text-white p-1.5 rounded-full mx-2" aria-hidden="true" :fill="'#fff'"/>
                </div>
    </div>
    <confirm-modal :show="confirmInvite" @confirm="inviteFriend()" @canceled="confirmInvite=false" 
    :confirmtitle="'Add friend'" 
    :confirmtext="'Are you sure you want to invite '+friendContact+'?'">
    <PersonAddIcon class="h-6 w-6 text-gray-500" aria-hidden="true" :fill="'#fff'"/>
    </confirm-modal>
  </div>
</template>

<script>
import PersonAddIcon from '@/assets/icons/person-add-outline.vue'
import ShareIcon from '@/assets/icons/share.vue'
import FacebookIcon from '@/assets/icons/facebook.vue'
import TwitterIcon from '@/assets/icons/twitter.vue'
import InstagramIcon from '@/assets/icons/instagram.vue'
import ConfirmModal from '@/components/confirmModal.vue'
import CustomButton from '@/components/button.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

// import Invite from '@/assets/invite.vue'
export default  {
  setup () {
    return { v$: useVuelidate() }
  },

  components:{
    PersonAddIcon,
    ConfirmModal,
    ShareIcon,
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    // Invite
    CustomButton
  },
  data(){
    return{
      confirmInvite:false,
      friendContact:null,
    }
  },
  validations() {
    return {
      friendContact: {
        required,
        email
      }
    }  
  },
  computed:{
    canSendInvite(){
      if(this.friendContact==null || this.friendContact.length==0) return false
      return true
    }
  },
  methods:{
    async submitForm(){
      const isFormCorrect = await this.v$.friendContact.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (isFormCorrect){
        this.confirmInvite=true
      }
    },

    inviteFriend(){
      this.$store.commit('addDeposit', {
        deposit:0,
        tickets: 100
      })
      this.$store.commit('addItemToActionList', {
        type:'reffer',
        value: 100,
        friend:this.friendContact
      })
      this.friendContact=null
      this.confirmInvite=false
      this.$router.push('account')
    }
  }

}
</script>